
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #CCD0CF;
    background-color: #06141B;
  }
  button {
    background-color: #4A5C6A;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  button:hover {
    background-color: #9BA8AB;
  }
  .dropdown-toggle::after {
    display: none;
}